<template>
<v-app>
    <v-container fluid class="content">
        <v-layout row fill-height>
            <slot name="imagem" />

            <slot name="login" />
        </v-layout>
    </v-container>
</v-app>
</template>

<script>
export default {
  name: 'LoginTemplateComponent',
  data () {
    return {
      usuario: false
    }
  },
  created () {
    const user = sessionStorage.getItem('usuario')
    if (user) {
      this.usuario = JSON.parse(user)
      this.$router.push({
        name: 'dash'
      })
    }
  },
  components: {

  }
}
</script>

<style scoped>
.content {
    padding: 0;
}

</style>
