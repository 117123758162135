<template>
<login-template-component>

    <v-flex slot="imagem" xs7 class="imagem">
        <img src="/assets/img/direciona_c.jpg" alt="Paisagem">
    </v-flex>

    <v-layout slot="login" xs5 align-center justify-center class="login">

        <v-layout align-center justify-center column>

            <v-flex xs12>
                <img class="responsive-img" style="width: 400px;" src="/assets/img/logo.webp">
            </v-flex>

            <v-flex xs12 style="display: inline-block; padding: 32px 10px 0px 10px; width: 50%">
                <form autocomplete="off" @submit.prevent="login">
                    <v-text-field dark v-model="user.email" label="E-mail" autofocus autocomplete="off"></v-text-field>
                    <v-text-field dark v-model="user.password" label="Senha" type="password" autocomplete="off"></v-text-field>
                    <v-btn class="lime darken-3 black--text" block type="submit">Acessar</v-btn>
                </form>
            </v-flex>

        </v-layout>

    </v-layout>

</login-template-component>
</template>

<script>
import LoginTemplateComponent from '@/views/templates/LoginTemplateComponent'
import axios from 'axios'

export default {
  name: 'Login',
  created () {
    this.$vuetify.theme.dark = true
  },
  data () {
    return {
      user: {
        email: '',
        password: ''
      }
    }
  },
  methods: {
    login () {
      this.$store.dispatch('login', this.user)
        .then((response) => {
          if (response.data.user.token) {
            this.$store.commit('SET_USER', response.data.user)
            const token = response.data.user.token
            sessionStorage.setItem('usuario', JSON.stringify(response.data.user))
            axios.defaults.headers.common.Authorization = `Bearer ${token}`
            this.$router.push({
              name: 'dash'
            })
          } else if (response.data.status === false) {
            this.$swal({
              position: 'center',
              icon: 'warning',
              title: response.data.msg,
              showConfirmButton: true,
              timer: 6000
            })
          } else {
            this.$swal({
              position: 'center',
              icon: 'error',
              title: response.data.msg,
              showConfirmButton: true,
              timer: 6000
            })
          }
        })
        .catch(error => {
          if (error.response === undefined) {
            this.$swal({
              position: 'center',
              icon: 'warning',
              title: 'Não foi possível se conectar com o servidor',
              showConfirmButton: true,
              timer: 6000
            })
          } else {
            this.$swal({
              position: 'center',
              icon: 'error',
              title: error.response.data.msg,
              showConfirmButton: true,
              timer: 6000
            })
          }
        })
    }
  },
  components: {
    LoginTemplateComponent
  }
}
</script>

<style scoped>
.imagem>img {
    width: 100%;
    height: 99.4vh;
    object-fit: cover;
    object-position: right;
}

.login {
    font-family: 'Roboto', sans-serif;
}
</style>
